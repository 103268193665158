import React, { useMemo } from "react";
import { Heading, Box, Icon, chakra, useMediaQuery } from "@chakra-ui/react";
import { ArrowDownCircle } from "react-feather";
import useSWR from "swr";
import { useTranslation } from "next-i18next";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Loader from "@/components/common/Loader";
import ErrorMessage from "@/components/common/ErrorMessage";
import useCurrentLocation from "@/hooks/useCurrentLocation";
import GarageCard from "../Garage/GarageCard";
import SadBox from "../common/SadBox";

const GaragesSection = (): React.ReactElement => {
  const { t } = useTranslation();
  const [isLargerThan1500] = useMediaQuery("(min-width: 1500px)");
  const { location, error: locationError } = useCurrentLocation();
  const {
    data: garages,
    error: garagesError,
    mutate: refreshGarages,
  } = useSWR(
    `/garage/?coordinates[]=${location?.lat}&coordinates[]=${location?.lng}`
  );

  const sliderSettings = useMemo(
    () => ({
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow:
        (isLargerThan1500 && garages?.length < 5) ||
        (!isLargerThan1500 && garages?.length < 4)
          ? garages?.length
          : isLargerThan1500
          ? 5
          : 4,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    }),
    [garages?.length, isLargerThan1500]
  );

  return (
    <Box
      pb="50px"
      id="section-garages"
      borderTopColor="yellow.500"
      borderTopWidth="2px"
    >
      <Box
        maxW={isLargerThan1500 ? "1100px" : "975px"}
        px={{ base: "15px", sm: "50px", xl: 0 }}
        mx="auto"
      >
        <Heading size="lg" variant="box" color="white" bg="yellow.500">
          {t("home.newGarages")}
          <chakra.a href="#section-garages" lineHeight={0}>
            <Icon w="30px" h="30px" ml="15px" as={ArrowDownCircle} />
          </chakra.a>
        </Heading>

        {!garages && !garagesError && <Loader />}

        {(locationError || garagesError) && (
          <ErrorMessage message={locationError || t("fetchError")} />
        )}

        {garages && !garagesError && (
          <Slider {...sliderSettings}>
            {garages?.map((garage, index) => (
              <GarageCard
                key={`garage_${index}`}
                garage={garage}
                refreshGarages={refreshGarages}
              />
            ))}
          </Slider>
        )}

        {garages?.length === 0 && !garagesError && !locationError && (
          <SadBox title={t("home.neighbourGaragesNotFound")} small />
        )}
      </Box>
    </Box>
  );
};

export default GaragesSection;
