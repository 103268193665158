import React, { useState } from "react";
import {
  Box,
  InputGroup,
  Input,
  InputRightElement,
  InputLeftElement,
  IconButton,
  Icon,
  BoxProps,
  Flex,
  Divider,
  useMediaQuery,
} from "@chakra-ui/react";
import { Search } from "react-feather";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { MapPin } from "react-feather";

import useLocalStorage from "@/hooks/useLocalStorage";

type Props = {
  setSearchTerm?: (t: string) => void;
  setLocationSearch?: (t: string) => void;
  home?: boolean;
  defaultValue?: string;
  placeholder?: string;
};

const SearchBar = ({
  setSearchTerm,
  setLocationSearch,
  home = false,
  defaultValue,
  placeholder,
  ...props
}: Props & BoxProps): React.ReactElement => {
  const [isLargerThan1500] = useMediaQuery("(min-width: 1500px)");
  const { t } = useTranslation();
  const router = useRouter();
  const [homeSearchTerm, setHomeSearchTerm] = useState("");
  const [homeLocationSearch, setHomeLocationSearch] = useState("");
  const [lastSearchTerm, setLastSearchTerm] = useLocalStorage(
    "lastSearchTerm",
    ""
  );

  return (
    <Box py="30px" bg="gray.100" {...props}>
      <Flex
        maxW={isLargerThan1500 ? "1100px" : "975px"}
        px={{ base: "15px", sm: "50px", xl: 0 }}
        mx="auto"
        direction={{ base: "column", sm: "row" }}
      >
        <InputGroup>
          <InputLeftElement h="100%" ml="12px" color="gray.500">
            <Icon w="20px" h="20px" as={Search} />
          </InputLeftElement>
          <Input
            placeholder={placeholder ? placeholder : t("home.search2")}
            size="xl"
            px="30px"
            borderWidth="2px"
            paddingLeft="40px"
            onChange={(e) => {
              setLastSearchTerm(e.target.value);

              home
                ? setHomeSearchTerm(e.target.value)
                : setSearchTerm(e.target.value);
            }}
            onKeyPress={(e) => {
              if (e.key === "Enter" && home) {
                e.preventDefault();
                router.push({
                  pathname: `/p`,
                  query: {
                    search: homeSearchTerm,
                  },
                });
              }
            }}
            defaultValue={defaultValue}
            borderTopRightRadius={{ base: "30px", sm: 0 }}
            borderBottomRightRadius={0}
            borderBottomLeftRadius={{ base: 0, sm: "30px" }}
            border={0}
          />
        </InputGroup>
        <Divider
          orientation="vertical"
          display={{ base: "none", sm: "block" }}
        />
        <Divider display={{ base: "block", sm: "none" }} />
        <InputGroup>
          <InputLeftElement
            h="100%"
            ml={{ base: "12px", sm: "7px" }}
            color="gray.500"
          >
            <Icon w="20px" h="20px" as={MapPin} />
          </InputLeftElement>
          <Input
            placeholder={t("product.area")}
            size="xl"
            px="30px"
            borderWidth="2px"
            borderTopRightRadius={{ base: 0, sm: "30px" }}
            borderTopLeftRadius={0}
            borderBottomLeftRadius={{ base: "30px", sm: 0 }}
            border={0}
            paddingLeft="37px"
            onChange={(e) => {
              home
                ? setHomeLocationSearch(e.target.value)
                : setLocationSearch(e.target.value);
            }}
          />
          <InputRightElement h="100%" mr="7px">
            <IconButton
              variant="blue"
              size="lg"
              aria-label={t("home.search")}
              d="inline-flex"
              alignItems="center"
              justifyContent="center"
              icon={<Icon w="20px" h="20px" as={Search} />}
              onClick={() =>
                home
                  ? router.push({
                      pathname: `/p`,
                      query: {
                        search: homeSearchTerm,
                        location: homeLocationSearch,
                      },
                    })
                  : null
              }
            />
          </InputRightElement>
        </InputGroup>
      </Flex>
    </Box>
  );
};

export default SearchBar;
