import React, { useMemo } from "react";
import {
  Heading,
  Box,
  Flex,
  Image as ChakraImage,
  Icon,
  chakra,
  useMediaQuery,
} from "@chakra-ui/react";
import useSWR from "swr";
import { useTranslation } from "next-i18next";
import getConfig from "next/config";
import { ArrowDownCircle } from "react-feather";

import Loader from "@/components/common/Loader";
import ErrorMessage from "@/components/common/ErrorMessage";
import SearchBar from "@/components/common/SearchBar";
import NewProductsSection from "@/components/Home/NewProductsSection";
import Category from "@/components/Home/Category";
import GaragesSection from "./GaragesSection";
import RecommendedProducts from "./RecommendedProducts";
import ForYouProductsSection from "./ForYouProducts";

const { publicRuntimeConfig } = getConfig();

const Home = (): React.ReactElement => {
  const [isLargerThan1500] = useMediaQuery("(min-width: 1500px)");
  const { t } = useTranslation();
  const { data: categoriesData, error: categoriesError } = useSWR("/category");
  const categoriesLoading = !categoriesData && !categoriesError;

  const recommendedCategoriesIds = useMemo(() => {
    if (categoriesData?.length > 0) {
      return {
        realEstateCatgoryId: categoriesData?.find(
          (category) => category?.name === "realEstate"
        )?.id,
        automotiveCategoryId: categoriesData?.find(
          (category) => category?.name === "automotive"
        )?.id,
        fashionCategoryId: categoriesData?.find(
          (category) => category?.name === "fashion"
        )?.id,
        electronicsCategoryId: categoriesData?.find(
          (category) => category?.name === "electronics"
        )?.id,
      };
    } else
      return {
        realEstateCatgoryId: null,
        automotiveCategoryId: null,
        fashionCategoryId: null,
        electronicsCategoryId: null,
      };
  }, [categoriesData]);

  const categories = useMemo(() => {
    if (categoriesData?.length) {
      const servicesIndex = categoriesData?.findIndex(
        (category) => category?.name === "services"
      );

      const allCategories = categoriesData?.toSpliced(servicesIndex, 0, {
        id: 0,
        name: "free",
        productsCount: 0,
      });

      return allCategories?.sort((a, b) => b?.productsCount - a?.productsCount);
    } else return [];
  }, [categoriesData]);

  return (
    <>
      <Flex bg="white">
        <Flex
          maxW={isLargerThan1500 ? "1100px" : "975px"}
          px={{ base: "15px", sm: "50px", xl: 0 }}
          mx="auto"
        >
          <ChakraImage
            src={`${publicRuntimeConfig.basePath}/banner.jpg`}
            alt=""
            mx="auto"
            maxW="100%"
          />
        </Flex>
      </Flex>

      <SearchBar home />

      <ChakraImage
        src={`${publicRuntimeConfig.basePath}/banner2.jpg`}
        alt=""
        position="fixed"
        mt="-300px"
        top="calc(50vh + 39px)"
        left="0"
        zIndex={2}
        display={{ base: "none", xl: "block" }}
      />

      <Box
        pb="50px"
        id="section-categories"
        borderTopColor="red.500"
        borderTopWidth="2px"
        position="relative"
      >
        <Box
          maxW={isLargerThan1500 ? "1100px" : "975px"}
          px={{ base: "15px", sm: "50px", xl: 0 }}
          mx="auto"
        >
          <Heading size="lg" variant="box" bg="red.500" color="white">
            {t("home.popularCategories")}
            <chakra.a href="#section-categories" lineHeight={0}>
              <Icon w="30px" h="30px" ml="15px" as={ArrowDownCircle} />
            </chakra.a>
          </Heading>
          <Flex justify="center" wrap="wrap">
            {categoriesLoading && <Loader />}

            {categoriesError && <ErrorMessage />}

            {categories?.map((category, index) => (
              <Category key={`category_${index}`} category={category} />
            ))}
          </Flex>
        </Box>
      </Box>

      <GaragesSection />

      <ForYouProductsSection />

      <NewProductsSection />

      <RecommendedProducts
        realEstateCategoryId={recommendedCategoriesIds.realEstateCatgoryId}
        automotiveCategoryId={recommendedCategoriesIds.automotiveCategoryId}
        fashionCategoryId={recommendedCategoriesIds.fashionCategoryId}
        electronicsCategoryId={recommendedCategoriesIds.electronicsCategoryId}
      />
    </>
  );
};

export default Home;
