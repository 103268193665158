import React, { useRef } from "react";
import {
  Heading,
  Flex,
  Image as ChakraImage,
  LinkBox,
  LinkOverlay,
  Box,
  useDisclosure,
  Collapse,
  SimpleGrid,
  useOutsideClick,
  Text,
  Divider,
  Icon,
  useMediaQuery,
} from "@chakra-ui/react";
import getConfig from "next/config";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { ChevronRight } from "react-feather";

const { publicRuntimeConfig } = getConfig();

type CategoryProps = {
  category: any;
};

const Category = ({ category }: CategoryProps): React.ReactElement => {
  const [isLargerThan1500] = useMediaQuery("(min-width: 1500px)");
  const { t } = useTranslation();
  const router = useRouter();
  const { isOpen, onToggle, onClose } = useDisclosure();
  const childrensRef = useRef(null);
  useOutsideClick({
    ref: childrensRef,
    handler: onClose,
  });

  return (
    <>
      <LinkBox
        w={{ base: "195px", sm: "33.33333%", lg: "25%", xl: "20%" }}
        p="7.5px 0"
        role="group"
        cursor="pointer"
        onClick={() => {
          if (category?.name === "realEstate") {
            const flatCategory =
              category?.children?.find((c) => c?.name === "flat") ||
              category?.children?.[0];

            router.push(
              `/p?category=${category?.id}&categoryName=realEstate&mainSubCategory=${flatCategory?.id}`
            );
          } else if (category?.name === "automotive") {
            const carsCategory =
              category?.children?.find((c) => c?.name === "automotive_cars") ||
              category?.children?.[0];

            router.push(
              `/p?category=${category?.id}&categoryName=automotive&mainSubCategory=${carsCategory?.id}`
            );
          } else
            category?.name === "free"
              ? router.push(`/p?free=true`)
              : router.push(`/p?category=${category?.id}`);
        }}
        className={`cat-${category?.name}`}
      >
        <Flex
          align="center"
          justify="center"
          w="100%"
          px={isLargerThan1500 ? "65px" : { base: "65px", sm: "65px" }}
          pt={isLargerThan1500 ? "40px" : { base: "38px", sm: "35px" }}
          pb={isLargerThan1500 ? "27px" : "27px"}
          mx="auto"
          transition="all .25s ease-in-out"
          _groupHover={{
            transform: "scale(1.05)",
          }}
          position="relative"
        >
          <Box position="absolute" top={0}>
            <ChakraImage
              src={`${publicRuntimeConfig.basePath}/garage-new.png`}
              alt=""
            />
          </Box>
          <ChakraImage
            src={`${publicRuntimeConfig.basePath}/${category?.name}.png`}
            alt=""
            w="100%"
            h="100%"
            objectFit="contain"
            position="relative"
          />
        </Flex>
        <Heading
          mb={0}
          mt="0px"
          variant="blue"
          textAlign="center"
          fontSize={isLargerThan1500 ? "14px" : "14px"}
          px={isLargerThan1500 ? "20px" : "32px"}
          py={isLargerThan1500 ? "0px" : "2px"}
        >
          <LinkOverlay>{`${t(`categories.${category?.name}`)}`}</LinkOverlay>
        </Heading>
      </LinkBox>

      <Collapse
        in={isOpen}
        style={{ width: "100%", overflow: isOpen ? "visible" : "hidden" }}
        className={`subcat-${category?.name}`}
      >
        <Box
          ref={childrensRef}
          borderWidth="4px"
          borderStyle="solid"
          borderColor="gray.100"
          padding={{ base: "15px", md: "20px" }}
          mt="-8px"
        >
          <Flex
            align="center"
            fontWeight={"bold"}
            cursor="pointer"
            onClick={() => router.push(`/p?category=${category?.id}`)}
            mb="10px"
            role="group"
            lineHeight={1.2}
          >
            <Icon
              w="20px"
              h="20px"
              as={ChevronRight}
              color="black"
              mr="10px"
              _groupHover={{
                transform: "translateX(5px)",
              }}
              transition={"all 0.3s ease-in-out"}
            />
            {t(`categories.allFrom`, {
              category: t(`categories.${category?.name}`),
            })}
          </Flex>
          <SimpleGrid
            columns={{ base: 1, md: 4 }}
            spacingX={3}
            spacingY={2}
            alignItems="flex-start"
          >
            {category?.children?.map((children, index) => (
              <Flex
                key={`category_child_${index}`}
                alignItems="center"
                cursor="pointer"
                onClick={() => router.push(`/p?category=${children?.id}`)}
                _hover={{
                  bg: "gray.200",
                }}
                p="7.5px 15px"
                lineHeight={1.4}
                borderRadius="25px"
                transition={"all 0.3s ease-in-out"}
                fontSize={14}
              >
                <Icon
                  w="16px"
                  h="16px"
                  as={ChevronRight}
                  color="black"
                  mr="5px"
                  _groupHover={{
                    transform: "translateX(5px)",
                  }}
                  transition={"all 0.3s ease-in-out"}
                />
                <Text>{t(`categories.${children?.name}`)}</Text>
              </Flex>
            ))}
          </SimpleGrid>
        </Box>
      </Collapse>
    </>
  );
};

export default Category;
