import React, { useMemo } from "react";
import {
  Heading,
  Box,
  Flex,
  Icon,
  chakra,
  useMediaQuery,
} from "@chakra-ui/react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import useSWR from "swr";
import { useSession } from "next-auth/react";
import { useTranslation } from "next-i18next";

import useCurrentLocation from "@/hooks/useCurrentLocation";
import Loader from "@/components/common/Loader";
import ErrorMessage from "@/components/common/ErrorMessage";
import { ArrowDownCircle } from "react-feather";
import ProductCard from "@/components/Product/ProductCard";

type Props = {
  realEstateCategoryId: number;
  automotiveCategoryId: number;
  fashionCategoryId: number;
  electronicsCategoryId: number;
};

const RecommendedProducts = ({
  realEstateCategoryId,
  automotiveCategoryId,
  fashionCategoryId,
  electronicsCategoryId,
}: Props): React.ReactElement => {
  const [isLargerThan1500] = useMediaQuery("(min-width: 1500px)");
  const { t } = useTranslation();
  const { location, error: locationError } = useCurrentLocation();
  const { data: session, status } = useSession();
  const {
    data: realEstateProducts,
    error: realEstateProductsError,
    mutate: mutateRealEstateProducts,
    isLoading: realEstateProductsLoading,
  } = useSWR(
    realEstateCategoryId
      ? `/product?coordinates[]=${location?.lat}&coordinates[]=${
          location?.lng
        }${
          status === "authenticated" ? `&userId=${session?.user?.id}` : ""
        }&categories[]=${realEstateCategoryId}&take=8`
      : null
  );
  const {
    data: automotiveProducts,
    error: automotiveProductsError,
    mutate: mutateAutomotiveProducts,
    isLoading: automotiveProductsLoading,
  } = useSWR(
    automotiveCategoryId
      ? `/product?coordinates[]=${location?.lat}&coordinates[]=${
          location?.lng
        }${
          status === "authenticated" ? `&userId=${session?.user?.id}` : ""
        }&categories[]=${automotiveCategoryId}&take=8`
      : null
  );
  const {
    data: fashionProducts,
    error: fashionProductsError,
    mutate: mutateFashionProducts,
    isLoading: fashionProductsLoading,
  } = useSWR(
    fashionCategoryId
      ? `/product?coordinates[]=${location?.lat}&coordinates[]=${
          location?.lng
        }${
          status === "authenticated" ? `&userId=${session?.user?.id}` : ""
        }&categories[]=${fashionCategoryId}&take=8`
      : null
  );
  const {
    data: electronicsProducts,
    error: electronicsProductsError,
    mutate: mutateElectronicsProducts,
    isLoading: electronicsProductsLoading,
  } = useSWR(
    electronicsCategoryId
      ? `/product?coordinates[]=${location?.lat}&coordinates[]=${
          location?.lng
        }${
          status === "authenticated" ? `&userId=${session?.user?.id}` : ""
        }&categories[]=${electronicsCategoryId}&take=8`
      : null
  );

  const productsError = useMemo(() => {
    if (
      realEstateProductsError ||
      automotiveProductsError ||
      fashionProductsError ||
      electronicsProductsError
    )
      return true;
    else return false;
  }, [
    automotiveProductsError,
    electronicsProductsError,
    fashionProductsError,
    realEstateProductsError,
  ]);
  const productsLoading = useMemo(() => {
    if (
      realEstateProductsLoading ||
      automotiveProductsLoading ||
      fashionProductsLoading ||
      electronicsProductsLoading
    )
      return true;
    else return false;
  }, [
    automotiveProductsLoading,
    electronicsProductsLoading,
    fashionProductsLoading,
    realEstateProductsLoading,
  ]);

  const sliderSettings = useMemo(
    () => ({
      dots: false,
      infinite: true,
      speed: 500,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    }),
    []
  );
  const realEstateSliderSettings = useMemo(
    () => ({
      ...sliderSettings,
      slidesToShow:
        (isLargerThan1500 && realEstateProducts?.data?.length < 5) ||
        (!isLargerThan1500 && realEstateProducts?.data?.length < 4)
          ? realEstateProducts?.data?.length
          : isLargerThan1500
          ? 5
          : 4,
    }),
    [isLargerThan1500, realEstateProducts?.data?.length, sliderSettings]
  );
  const automotiveSliderSettings = useMemo(
    () => ({
      ...sliderSettings,
      slidesToShow:
        (isLargerThan1500 && automotiveProducts?.data?.length < 5) ||
        (!isLargerThan1500 && automotiveProducts?.data?.length < 4)
          ? automotiveProducts?.data?.length
          : isLargerThan1500
          ? 5
          : 4,
    }),
    [isLargerThan1500, automotiveProducts?.data?.length, sliderSettings]
  );
  const fashionSliderSettings = useMemo(
    () => ({
      ...sliderSettings,
      slidesToShow:
        (isLargerThan1500 && fashionProducts?.data?.length < 5) ||
        (!isLargerThan1500 && fashionProducts?.data?.length < 4)
          ? fashionProducts?.data?.length
          : isLargerThan1500
          ? 5
          : 4,
    }),
    [isLargerThan1500, fashionProducts?.data?.length, sliderSettings]
  );
  const electronicsSliderSettings = useMemo(
    () => ({
      ...sliderSettings,
      slidesToShow:
        (isLargerThan1500 && electronicsProducts?.data?.length < 5) ||
        (!isLargerThan1500 && electronicsProducts?.data?.length < 4)
          ? electronicsProducts?.data?.length
          : isLargerThan1500
          ? 5
          : 4,
    }),
    [isLargerThan1500, electronicsProducts?.data?.length, sliderSettings]
  );

  const mutateProducts = () => {
    mutateRealEstateProducts();
    mutateAutomotiveProducts();
    mutateFashionProducts();
    mutateElectronicsProducts();
  };

  return (
    <Box
      pb="10px"
      bg="gray.100"
      id={`section-recommended`}
      borderTopColor={`green.500`}
      borderTopWidth="2px"
    >
      <Box
        maxW={isLargerThan1500 ? "1100px" : "975px"}
        px={{ base: "15px", sm: "50px", xl: 0 }}
        mx="auto"
      >
        <Flex align="center" justifyContent={"space-between"}>
          <Heading size="lg" variant="box" color="white" bg={`green.500`}>
            {t(`home.recommended`)}
            <chakra.a href={`#section-recommended`} lineHeight={0}>
              <Icon w="30px" h="30px" ml="15px" as={ArrowDownCircle} />
            </chakra.a>
          </Heading>
        </Flex>

        {productsLoading && !locationError && <Loader />}

        {(locationError || productsError) && (
          <ErrorMessage message={locationError || t("fetchError")} />
        )}

        <Box>
          <Slider {...realEstateSliderSettings}>
            {realEstateProducts?.data?.map((product, index) => (
              <ProductCard
                product={product}
                key={index}
                mutate={mutateProducts}
              />
            ))}
          </Slider>
        </Box>

        <Box mt={5}>
          <Slider {...automotiveSliderSettings}>
            {automotiveProducts?.data?.map((product, index) => (
              <ProductCard
                product={product}
                key={index}
                mutate={mutateProducts}
              />
            ))}
          </Slider>
        </Box>

        <Box mt={5}>
          <Slider {...fashionSliderSettings}>
            {fashionProducts?.data?.map((product, index) => (
              <ProductCard
                product={product}
                key={index}
                mutate={mutateProducts}
              />
            ))}
          </Slider>
        </Box>

        <Box mt={5}>
          <Slider {...electronicsSliderSettings}>
            {electronicsProducts?.data?.map((product, index) => (
              <ProductCard
                product={product}
                key={index}
                mutate={mutateProducts}
              />
            ))}
          </Slider>
        </Box>
      </Box>
    </Box>
  );
};

export default RecommendedProducts;
