import React, { useMemo } from "react";
import {
  Heading,
  Box,
  Flex,
  Icon,
  chakra,
  useMediaQuery,
} from "@chakra-ui/react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import useSWR from "swr";
import { useSession } from "next-auth/react";
import { useTranslation } from "next-i18next";

import useCurrentLocation from "@/hooks/useCurrentLocation";
import Loader from "@/components/common/Loader";
import ErrorMessage from "@/components/common/ErrorMessage";
import SadBox from "@/components/common/SadBox";
import { ArrowDownCircle } from "react-feather";
import ProductCard from "@/components/Product/ProductCard";

const NewProductsSection = (): React.ReactElement => {
  const [isLargerThan1500] = useMediaQuery("(min-width: 1500px)");
  const { t } = useTranslation();
  const { location, error: locationError } = useCurrentLocation();
  const { data: session, status } = useSession();
  const {
    data: products,
    error: productsError,
    mutate: mutateProducts,
  } = useSWR(
    `/product?coordinates[]=${location?.lat}&coordinates[]=${location?.lng}${
      status === "authenticated" ? `&userId=${session?.user?.id}` : ""
    }&sortBy=createdDate&take=8`
  );

  const sliderSettings = useMemo(
    () => ({
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow:
        (isLargerThan1500 && products?.data?.length < 5) ||
        (!isLargerThan1500 && products?.data?.length < 4)
          ? products?.data?.length
          : isLargerThan1500
          ? 5
          : 4,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    }),
    [isLargerThan1500, products?.data?.length]
  );

  return (
    <Box
      pb="50px"
      bg="gray.100"
      id={`section-newest`}
      borderTopColor={`red.500`}
      borderTopWidth="2px"
    >
      <Box
        maxW={isLargerThan1500 ? "1100px" : "975px"}
        px={{ base: "15px", sm: "50px", xl: 0 }}
        mx="auto"
      >
        <Flex align="center" justifyContent={"space-between"}>
          <Heading size="lg" variant="box" color="white" bg={`red.500`}>
            {t(`home.newest`)}
            <chakra.a href={`#section-newest`} lineHeight={0}>
              <Icon w="30px" h="30px" ml="15px" as={ArrowDownCircle} />
            </chakra.a>
          </Heading>
        </Flex>

        {!products && !productsError && !locationError && <Loader />}

        {(locationError || productsError) && (
          <ErrorMessage message={locationError || t("fetchError")} />
        )}

        <Slider {...sliderSettings}>
          {products?.data?.map((product, index) => (
            <ProductCard
              product={product}
              key={index}
              mutate={mutateProducts}
            />
          ))}
        </Slider>

        {products?.count === 0 && !productsError && !locationError && (
          <SadBox title={t("home.neighbourProductsNotFound")} small />
        )}
      </Box>
    </Box>
  );
};

export default NewProductsSection;
