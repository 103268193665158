import React, { useMemo } from "react";
import {
  Heading,
  Box,
  Flex,
  Icon,
  chakra,
  useMediaQuery,
} from "@chakra-ui/react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import useSWR from "swr";
import { useSession } from "next-auth/react";
import { useTranslation } from "next-i18next";

import useCurrentLocation from "@/hooks/useCurrentLocation";
import Loader from "@/components/common/Loader";
import ErrorMessage from "@/components/common/ErrorMessage";
import { ArrowDownCircle } from "react-feather";
import ProductCard from "@/components/Product/ProductCard";
import useLocalStorage from "@/hooks/useLocalStorage";

const ForYouProductsSection = (): React.ReactElement => {
  const [isLargerThan1500] = useMediaQuery("(min-width: 1500px)");
  const { t } = useTranslation();
  const { location, error: locationError } = useCurrentLocation();
  const { data: session, status } = useSession();
  const [favoriteCategories, setFavoriteCategories] = useLocalStorage(
    "favoriteCategories",
    []
  );
  const [lastSearchTerm, setLastSearchTerm] = useLocalStorage(
    "lastSearchTerm",
    ""
  );
  const uniqueFavriteCategories = useMemo(
    () =>
      Array.from(
        new Set(
          favoriteCategories?.filter(
            (categoryId) => typeof categoryId === "number"
          )
        )
      ),
    [favoriteCategories]
  );
  const {
    data: favoriteCategoriesProducts,
    error: favoriteCategoriesProductsError,
    mutate: mutateFavoriteCategoriesProducts,
    isLoading: favoriteCategoriesProductsLoading,
  } = useSWR(
    uniqueFavriteCategories
      ? `/product?coordinates[]=${location?.lat}&coordinates[]=${
          location?.lng
        }${
          status === "authenticated" ? `&userId=${session?.user?.id}` : ""
        }&${uniqueFavriteCategories
          ?.slice(0, 5)
          ?.map((categoryId) => `&categories[]=${categoryId}`)}&take=8`
      : null
  );
  const {
    data: lastSearchTermProducts,
    error: lastSearchTermProductsError,
    mutate: mutateLastSearchTermProducts,
    isLoading: lastSearchTermProductsLoading,
  } = useSWR(
    lastSearchTerm
      ? `/product?coordinates[]=${location?.lat}&coordinates[]=${
          location?.lng
        }${
          status === "authenticated" ? `&userId=${session?.user?.id}` : ""
        }&searchTerm=${lastSearchTerm}&take=8`
      : null
  );

  const mutateProducts = () => {
    mutateFavoriteCategoriesProducts();
    mutateLastSearchTermProducts();
  };

  const products = useMemo(() => {
    const data = [];

    if (favoriteCategoriesProducts?.data?.length > 0)
      data?.push(favoriteCategoriesProducts?.data);

    if (lastSearchTermProducts?.data?.length > 0)
      data?.push(lastSearchTermProducts?.data);

    const dataToReturn = data?.flat(1);

    if (dataToReturn?.length > 0)
      return dataToReturn?.filter(
        (obj, index, self) => index === self.findIndex((o) => o.id === obj.id)
      );
    else return dataToReturn;
  }, [favoriteCategoriesProducts?.data, lastSearchTermProducts?.data]);

  const productsLoading = useMemo(() => {
    if (favoriteCategoriesProductsLoading || lastSearchTermProductsLoading)
      return true;
    else return false;
  }, [favoriteCategoriesProductsLoading, lastSearchTermProductsLoading]);

  const productsError = useMemo(() => {
    if (favoriteCategoriesProductsError && lastSearchTermProductsError)
      return true;
    else return false;
  }, [favoriteCategoriesProductsError, lastSearchTermProductsError]);

  const sliderSettings = useMemo(
    () => ({
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow:
        (isLargerThan1500 && products?.length < 5) ||
        (!isLargerThan1500 && products?.length < 4)
          ? products?.length
          : isLargerThan1500
          ? 5
          : 4,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    }),
    [isLargerThan1500, products?.length]
  );

  return (
    <Box
      display={
        (!favoriteCategories && !lastSearchTerm) || products?.length === 0
          ? "none"
          : "block"
      }
      pb="50px"
      bg="gray.100"
      id={`section-forYou`}
      borderTopColor={`blue.500`}
      borderTopWidth="2px"
    >
      <Box
        maxW={isLargerThan1500 ? "1100px" : "975px"}
        px={{ base: "15px", sm: "50px", xl: 0 }}
        mx="auto"
      >
        <Flex align="center" justifyContent={"space-between"}>
          <Heading size="lg" variant="box" color="white" bg={`blue.500`}>
            {t(`home.forYou`)}
            <chakra.a href={`#section-forYou`} lineHeight={0}>
              <Icon w="30px" h="30px" ml="15px" as={ArrowDownCircle} />
            </chakra.a>
          </Heading>
        </Flex>

        {productsLoading && !locationError && <Loader />}

        {(locationError || productsError) && (
          <ErrorMessage message={locationError || t("fetchError")} />
        )}

        <Slider {...sliderSettings}>
          {products?.map((product, index) => (
            <ProductCard
              product={product}
              key={index}
              mutate={mutateProducts}
            />
          ))}
        </Slider>
      </Box>
    </Box>
  );
};

export default ForYouProductsSection;
